#cardBtn{
    background-color: rgba(43,45,106,1) !important;
    border: none;
    transition: 0.3s ease-out;
    border-radius: 0.65rem;
}

#cardBtn:hover{
    background-color: white !important;
    color: black;
}
.khosherKiUnderLine{
    border-bottom: solid;
    
  }

@media screen and (max-width: 600px) {
    .contactImage {
      display: none;
    }
  }

@media screen and (max-width: 600px) {
      .khosherKiUnderLine{
        border-bottom: solid;
        padding-bottom: 0rem;
      }
      #headdd{
        font-size: 30px;
      }
    
  }

#parentDiv{
    background-color: #92A9BD;
    background-size: cover;
}
.parentNews{
    margin-top: 20px;
    margin-bottom: 20px;    
}
.inptControl {
    display: block;
    width: 100%;
    min-height: calc(1.5em + .75rem + 2px);
    padding: .575rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 52px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.crdSubs{
    padding: 20px;
    background-color: #eee;
    padding-bottom: 50px;
    padding-top: 50px;
    background-color: white;
    border: none;
}



.crdSubs:focus {
    color: #495057;
    background-color: #fff;
    border-color: #f9a826;
    outline: 0;
    box-shadow: none
}
.border-rad {
    /* border-top-right-radius: 28px;
    border-bottom-right-radius: 28px; */
    color: #fff;
    background-color: rgb(7, 78, 184);
    border-color:rgb(7, 78, 184);
    font-family: "Comfortaa";
    transition: 0.4 ease-in-out;
}
.subsBtn{
    border-radius: 0px 20px 20px 0px !important;
    margin-top: 7px;
    background-color: rgb(7, 78, 184);
    border-color:rgb(7, 78, 184);
    font-family: "Comfortaa";
    transition: 0.4 ease-in-out;
}

.border-rad:hover {
    background-color: white;
    color: black;
    border: 1px solid rgba(7,78,184);
    
}
.inptControl {
    
    display: block;
    width: 100%;
    min-height: calc(1.5em + .75rem + 2px);
    padding: .575rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 52px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    font-family: "Roboto";

}






















@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&family=Poppins:wght@100;300&family=Rancho&family=Roboto+Condensed:wght@300&family=Roboto+Slab:wght@100&family=Roboto:wght@300&display=swap");

*{
    font-family: 'Roboto';
}

.dwnloadBtn {
    background-color: rgb(53 ,19, 90);
    border: none;
    color: white;
    padding: 12px 30px;
    cursor: pointer;
    font-size: 17px;
  }
  
  /* Darker background on mouse-over */
  .dwnloadBtn:hover {
    background-color: white;
  }

  @media screen and (max-width: 600px) {
    #imageKosher {
      display: none;
    }
    .crousell{
      display: none;
    }
    .aboutDiv{
      margin-top: 5rem;
    }
    #headdd{
      font-size: 30px;
    }
  }

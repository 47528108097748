  
   @media screen and (max-width: 300px) {
    .cardImage {
     margin-left: 0rem !important;
    }
  }
/*   
  @media screen and (max-width: 300px) {
    .cardImage {
     margin-left: 1rem !important;
    }
  }
  
  @media screen and (max-width: 300px) {
    .cardImage {
     margin-left: 1rem !important;
    }
  } */

#quoteImageee{
    padding-top: 1rem;
    width: 6rem;
    height: auto;
}
.quote{
    border-top: 3px solid blue;
  }

.motivaitonalArea{
    background-color: white; 
   
}


.contennnt{
    font-size: 18px;
    padding-top: 1rem;
  }
  

  @media screen and (max-width: 600px) {
    #quoteImageee {
      display: none;
    }
  }

  
  


  @media screen and (max-width: 600px) {
    .quoteImage {
      display: none;

    }
  }

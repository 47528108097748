#footer {
    background: rgba(43,45,106,1);
    padding: 0 0 30px 0;
    color: black;
    font-size: 14px
}



#footer .footer-newsletter form input[type="email"] {
    border: 0;
    padding: 8px;
    width: calc(100% - 140px)
}

#footer .footer-newsletter form input[type="submit"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 30px;
    margin: 3px;
    background: #e96b56;
    color: black;
    transition: 0.3s;
    border-radius: 50px
}

#footer .footer-newsletter form input[type="submit"]:hover {
    background: black
}

#footer .footer-top {
    background: rgba(43,45,106,1);
    border-top: 1px solid #f8f9fa;
    border-bottom: 1px solid #f8f9fa;
    padding: 60px 0 30px 0
}

#footer .footer-top .footer-info {
    margin-bottom: 30px
}

#footer .footer-top .footer-info h3 {
    font-size: 18px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700
}

#footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "roboto";
    color: black
}

#footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #545454;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s
}

#footer .footer-top .social-links a:hover {
    background: rgba(43,45,106,1);
    color: #fff;
    text-decoration: none
}

#footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 12px
}

#footer .footer-top .footer-links {
    margin-bottom: 30px
}

#footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0
}

#footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: rgba(43,45,106,1);
    font-size: 18px;
    line-height: 1
}

#footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center
}

#footer .footer-top .footer-links ul li:first-child {
    padding-top: 0
}

#footer .footer-top .footer-links ul a {
    color: black;
    transition: 0.3s;
    display: inline-block;
    line-height: 1
}

#footer .footer-top .footer-links ul a:hover {
    color: rgba(43,45,106,1)
}

#footer .footer-top .footer-contact {
    margin-bottom: 30px
}

#footer .footer-top .footer-contact p {
    line-height: 26px
}

#footer .copyright {
    text-align: center;
    padding-top: 30px
}

#footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: black
}
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&family=Poppins:wght@100;300&family=Rancho&family=Roboto+Condensed:wght@300&family=Roboto+Slab:wght@100&family=Roboto:wght@300&display=swap");

* {
  font-family: "Roboto";
}
.parentDiv {
  background-color: white;
  
}
.parentQualityControlContent {
  background-color: white;
}

.contennnt {
  font-size: 16px;
  padding-top: 1rem;
}

#qualitycontrolimg{
  margin-top: -42px;
}


@media screen and (max-width: 600px) {
  #headdd{
    font-size: 25px !important;
  }
  .contennnt {
    font-size: 15px !important;
  }
  #qualitycontrolimg{
    margin-top: 3rem !important;
  }
  
}

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&family=Poppins:wght@100;300&family=Rancho&family=Roboto+Condensed:wght@300&family=Roboto+Slab:wght@100&family=Roboto:wght@300&display=swap');

* {
    box-sizing: border-box;
    font-family: 'Roboto';
  }
  
  /* Style inputs */
  input[type=text], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    border-radius: 	0.625rem;
  }

  
  button[type=submit] {
    background-color: #04AA6D;
    color: white;
    padding: 12px 20px;
    border: none;
    cursor: pointer;
  }
  
  button[type=submit]:hover {
    background-color: #45a049;
  }
  
  /* Style the container/contact section */
  .container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 10px;
  }
  
  /* Create two columns that float next to eachother */
  .column {
    float: left;
    width: 50%;
    margin-top: 6px;
    padding: 20px;
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column, input[type=submit] {
      width: 100%;
      margin-top: 0;
    }
  }


  @media screen and (max-width: 769px) {
    .AboutImageDisplayNone {
      display: none;
    }
  }


  #subBtn{
    border-radius: 10px;
    background-color:#2b2d6a;
    transition: 0.3s ease-in-out;
  }
  #subBtn:hover{
      background-color: white;
      color: black;
  }

  
  
  @media screen and (max-width: 769px) {
    .mediaQueryforContactDetail {
      text-align: center;
      margin-bottom: 5%;
    }
  } 
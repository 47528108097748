


.lnks{
    transition: 0.3 ease-in-out;
    cursor: pointer;
    
}
.lnks:hover{
    border-bottom: 1px solid rgb(13, 110, 253);

}

#social {
    list-style: none;
    display: flex;
    margin-bottom: 0
}

.social-icon {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 14px;
    border-radius: 7px;
    margin-right: 11px;
    cursor: pointer
}


.social-facebook {
    background-color: #3b5999
}


.social-google {
    background-color: forestgreen;
}

.social-instagram {
    background-color: #dd4b39
}
.social-linkedin{
    background-color: blue
}

.social-icon i {
    transition: 0.4s all
}

.social-icon:hover i {
    transform: scale(1.1)
}
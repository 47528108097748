/* screen resolution 600 se bari  desktop kilie */
@media screen and (max-width: 600px){
  .carousel-item {
    height: 9rem  !important;
  }
}
/* screen resolution 600 se choti hoto mobile kelie */
/* @media screen and (max-width: 600px) {
  .carousel-item {
    height: 15rem;
  }
} */

/* .carousel {
    margin-bottom: 4rem;
  } */
  /* Since positioning the image, we need to help out the caption */
  .carousel-caption {
    bottom: 3rem;
    z-index: 10;
  }
  
  /* Declare heights because of positioning of img element */
  .carousel-item {
    height: 34rem;
  }
   .carousel-item > img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 32rem;
  } 
 
  /* .crouslImg{
    width:'100%';
    height:'auto';
  } */
  
  

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300&family=Rancho&family=Roboto+Condensed:wght@300&family=Roboto+Slab:wght@100&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&family=Poppins:wght@100;300&family=Rancho&family=Roboto+Condensed:wght@300&family=Roboto+Slab:wght@100&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&family=Poppins:wght@100;300&family=Rancho&family=Roboto+Condensed:wght@300&family=Roboto+Slab:wght@100&family=Roboto:wght@300&display=swap');


body{
  font-family:  Roboto !important;
  padding: 0%;
  margin:0%;
  background-color: white !important;
}




#crslImg{
max-width: 100%;
height: auto;
  
}



.ProductList{
    text-decoration: none;
    border-left: 5px solid #2b2d6a;
    transition: 200ms;
    border-top: none;
    border-bottom: none;
    border-right: none;
}

.ProductList:hover{
    border-left: 5px solid red;
    

}

.txt{
    color: black;
    font-weight: 500;
}
.txt:hover{
    text-decoration: none;
    
}

